<template>
  <div>
    <div class="qrs" style="padding: 20px">
      <img src="@/assets/img/wxpay.png" style="max-width: 180px" />
      <div v-if="!imgSrc" class="qr_error" @click="anewFun">
        <span class="demonstration">{{ Tishi }}</span>
      </div>
      <vue-qr v-if="imgSrc" :text="imgSrc" :size="150"
        style="margin-top: 30px"></vue-qr>
      <div style="margin-top: 30px; font-size: 20px">请使用微信扫一扫</div>
    </div>
  </div>
</template>
  
<script>
import myBus from '@/plugins/myBus.js'
import {wxQr,wxState} from "@/api/wxpay.js"
import vueQr from 'vue-qr'

export default {
  props:['msgVisible'],
  components: {
    vueQr
  },
  data() {
    return {
      imgSrc: '',//icon路径
      Tishi: '',
      times:null,
      Total:0
    }
  },
  watch:{
    msgVisible(val){
      if(!val)clearInterval(this.times)
    }
  },
  mounted(){
    myBus.$on("qr", function (val) {
      this.Total = val
      this.wxQrApi(val)
    }.bind(this));
  },
  methods: {
     
    //获取二维码api
    wxQrApi(val){
      this.Tishi = '加载中,请稍后'
      const that = this
      wxQr({Total:val}).then(res=>{
        ////console.log('二维码',res)
        if(res.data.Code==200){
          that.imgSrc = res.data.Data.CodeUrl
          this.times = setInterval(() => {
            that.wxStateApi(res.data.Data.OrderNo)
          }, 1000);
        }else{
          that.imgSrc = ''
          this.Tishi = '加载失败,重新加载'
        }
       
      })
    },
    //获取支付状态
    wxStateApi(val){
      const that = this
      wxState({OrderNo:val}).then(res=>{
        if(res.data.Data.T_status!=''){
          this.$emit('getPay',3)
          myBus.$emit('msgs',res.data.Data.T_status)
          clearInterval(that.times)
        }
      })
    },
    /**
     * 重新加载
     */
    async anewFun() {
      const that = this
      if(that.Tishi == '加载中,请稍后'){
        that.$message.error('加载中，请勿重复加载支付码')
        return
      }else{
        clearInterval(that.times)
        that.wxQrApi(that.Total)
      }
     
    }
  }
}
</script>
<style lang="scss">
.qrs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.qr_error {
  width: 150px;
  height: 150px;
  background: #f5f7fa;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>